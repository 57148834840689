<template>
  <!-- <div :class="{'home_warp':true,'idx':$route.path == '/index','user':$route.path == '/myinfo',''}"> -->
  <div :class="`home_warp ${getClass}`">
    <div class="info_mask"
         v-show="showDialog"></div>
    <div class="learningInfo"
         v-if="showDialog">
      <div class="icon_close"
           @click="toggleStudyInfo(0)">
        <img src="@/static/close1.png"
             alt=""
             title="关闭">
      </div>
      <StudyInfo style="width:100%;max-height:calc(100vh - 50rem);over-flow:auto" />
    </div>
    <div class="controller">
      <div class="warp"
           style="position:absolute;top:0;mix-blend-mode: hard-light;z-index:100"
           @click="toggleStudyInfo(1)">
        <div :class="{'icon':true}">
          <img src="@/static/icon-成长值.png"
               alt=""
               title="成长值与得分率">
        </div>
      </div>
      <div class="warp"
           @click="toHome('/index')">
        <div :class="{'icon':true,'bubble':$route.path == '/index'}">
          <img src="@/static/home.png"
               alt="">
        </div>
        <div class="text">
          首页
        </div>
      </div>
      <div class="warp"
           @click="toHome('/lerningCenter')">
        <div :class="{'icon':true,'bubble':$route.path == '/lerningCenter'}">
          <img src="@/static/xuexi.png"
               alt="">
        </div>
        <div class="text">
          学习中心
        </div>
      </div>
      <div class="warp"
           @click="toHome('/analyze')">
        <div :class="{'icon':true,'bubble':$route.path == '/analyze'}">
          <img src="@/static/fenxibaogao.png"
               alt="">
        </div>
        <div class="text">
          分析报告
        </div>
      </div>
      <div class="warp">

        <a-popover v-model:visible="visible"
                   title="导出"
                   placement="right"
                   trigger="click">
          <div :class="{'icon':true,'bubble':$route.path == '/analyze'}">
            <img src="@/static/数据中心.png"
                 style="width:110rem;height:110rem"
                 alt="">
          </div>
          <div class="text">
            数据中心
          </div>
          <template #content>
            <a-button type=""
                      @click="exportReport"
                      :disabled="btnDisabled">学科数据</a-button>
            <a-button type=""
                      @click="exportExplain(1)"
                      :disabled="btnDisabled">定制课件</a-button>

          </template>

        </a-popover>

      </div>
      <div class="warp"
           @click="toHome('/course')">
        <div :class="{ 'icon': true, 'bubble': $route.path == '/course' || $route.path == '/course_qiye' }">
          <img src="@/static/icon-course.png"
               alt="" />
        </div>
        <div class="text">
          优质课程
        </div>
      </div>
      <div class="warp"
           @click="toHome('/userInfo')">
        <div :class="{'icon':true,'bubble':$route.path.includes('/userInfo')}">
          <img src="@/static/wodeicon.png"
               alt="">
        </div>
        <div class="text">
          个人中心
        </div>
      </div>

    </div>
    <div class="right_view">
      <router-view></router-view>
    </div>
    <FormDialog ref="FormRef"></FormDialog>
  </div>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { getTeacherReport } from '@/api/home.js'
import { message } from "ant-design-vue";
import StudyInfo from "../userInfo/components/learningInfo.vue"
import FormDialog from "./components/formDialog.vue"
const router = useRouter()
const route = useRoute()
let showDialog = ref(false) //显示学习信息弹窗
let visible = ref(false);
let FormRef = ref(null)
const btnDisabled = ref(false);
const hide = () => {
  visible.value = false;

};
const base64ToBlob = (base64) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return ref(new Blob(byteArrays, { type: 'application/octet-stream' }));
};
const exportExplain = async (has_explain) => {
  FormRef.value.dialogVisible = true
  FormRef.value.has_explain = has_explain
  FormRef.value.checkedCitiesOpt = []
  FormRef.value.checkAll = false
};
const exportReport = async () => {
  btnDisabled.value = true;

  try {
    const res = await getTeacherReport();

    const a = document.createElement("a");
    const blob = base64ToBlob(res.data.base64Content);

    if (blob.size > 0) {
      btnDisabled.value = false;
    }
    a.href = window.URL.createObjectURL(blob.value);
    a.download = `分析报告.docx`;
    a.click();
    btnDisabled.value = false;
  } catch (error) {
    btnDisabled.value = false;
    // message.error(error.msg);
  }
};
const toggleStudyInfo = (bool) => {
  if (bool) {
    showDialog.value = !showDialog.value
  } else {
    showDialog.value = false
  }
}
let edu_file = ref(1)

const toHome = (str) => {
  // 等于1的话 是企业用户
  if (str == '/course' && edu_file.value == 1) {
    str = '/course_qiye'
  }
  router.push(str)
}

onMounted(() => {
  edu_file.value = JSON.parse(window.localStorage.getItem('userInfo')).edu_file
})

let getClass = computed(() => {
  var str = ''
  switch (route.path) {
    case '/index':
      str = 'idx'
      break;
    case '/analyze':
      str = 'fenxibaogao'
      break;
    case '/lerningCenter':
      str = 'studycenter'
      break;
    case '/reviewHandout':
      str = 'course'
      break;
    case '/course':
      str = 'course';
      break;
    case '/course_qiye':
      str = 'course';
      break;
  }
  if (route.path.includes("/userInfo")) {
    str = 'user'
  }
  return str
})

</script>

<style lang="scss" scoped>
.home_warp {
  display: flex;
  background: #f6f6f6;
  width: 100vw;
  min-width: 860px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  scrollbar-color: rgba(230, 230, 240, 0.6) rgba(250, 250, 250, 0.1);
  /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
  scrollbar-width: thin;
  // background-image: url("../static/indexbg.png");
  .controller {
    width: 110rem;
    margin-left: 40rem;
    margin-right: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    padding-top: 16vh;
    position: relative;
    .warp {
      width: 110rem;
      position: relative;
      color: white;
      font-size: 24rem;
      font-weight: 400;
      text-align: center;
      margin-top: 28rem;
      color: rgba(255, 255, 255, 0.85);
      cursor: pointer;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 75rem;
          height: 75rem;
          // margin-bottom: 12rem;
        }
      }
    }
  }
  .right_view {
    flex: 1;
  }
}
.bubble {
  top: 0;
  width: 110rem;
  height: 110rem;
  background-image: url("../../static/bubble.png");
  background-size: 110rem 110rem;
  background-repeat: no-repeat;
  & + .text {
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
  }
}
.idx {
  background-image: url("../../static/allBackg/index.png");
  background-color: #1850f6;
}
.user {
  background-image: url("../../static/allBackg/bg-我的.png");
  background-color: #1783f4;
}
.fenxibaogao {
  background-image: url("../../static/allBackg/bg-分析报告.png");
  background-color: #1783f4;
}
.studycenter {
  background-image: url("../../static/allBackg/bg-学习中心.png");
  background-color: #4367eb;
}
.course {
  background-image: url("../../static/allBackg/bg-优质课程.png");
  background-color: #285beb;
}
.learningInfo {
  position: fixed;
  top: 40rem;
  left: 215rem;
  background: #fff;
  border-radius: 30rem;
  width: 50%;
  min-width: 460px;
  padding: 10rem 5rem 0;
  // height: 90vh;
  z-index: 100;
  box-shadow: 6px 0 61px 1px #77c4ff;
  .icon_close {
    position: absolute;
    top: -20rem;
    right: -10rem;
    width: 50rem;
    height: 50rem;
    cursor: pointer;
    img {
      width: 120%;
      height: 120%;
    }
  }
}
.info_mask {
  position: fixed;
  z-index: 99;
  background: rgba(10, 20, 20, 0.2);
  width: 100%;
  height: 100%;
}
</style>